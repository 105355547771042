<!--
   /**
      * ourProject.vue
      * @module {src/modules/public}
      * @desc 前端我们的项目
      * @author shiqiu
      * @date 2021-01-21
      */
-->
<template>
  <div>
    <p class="project-title"><i class="iconfont icon-shangjia" style="color: #f90"></i>
      商家服务</p>
    <el-row :gutter="10" style="margin-top: 20px">
      <el-col :span="24">
        <nanny />
        <clean-keeping />
        <Housing />
        <plumbing />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import nanny from './nanny'
import cleanKeeping from './cleanKeeping'
import Housing from './Housing'
import plumbing from './plumbing'
export default {
  name: "Login",
  components:{nanny,cleanKeeping,Housing,plumbing},
  data() {
    return {

    }
  },
  methods: {

  },
  created() {
  }
}
</script>


<style lang="scss" scoped>

</style>
