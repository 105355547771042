<!--
   /**
      * index.vue
      * @module {src/modules/public}
      * @desc 前端首页
      * @author shiqiu
      * @date 2021-01-20
      */
-->
<template>
  <div>
    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width:100%">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 0px 2.5%">
            <el-row :gutter="10">
              <latest-service />
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width:100%">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 0px 2.5%">
            <el-row :gutter="10">
              <best-selling />
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width:100%">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 0px 2.5%">
            <el-row :gutter="10">
              <hot-service />
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width:100%">
      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">
        <div style="width: 100%; float: left;background-color: #fff;">
          <div style="padding: 0px 2.5%">
            <el-row :gutter="10">
              <service />
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
<!--    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width: 100%">-->
<!--      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">-->
<!--        <div style="width: 100%; float: left;background-color: #fff;">-->
<!--          <div style="padding: 0px 2.5%">-->
<!--            <el-row :gutter="10">-->
<!--              <chargingProjects />-->
<!--            </el-row>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--    <el-row type="flex" justify="center" style="margin-top: 10px;float: left;width: 100%">-->
<!--      <el-col :xs="24" :sm="24" :md="22" :lg="20" :xl="20">-->
<!--        <div style="width: 100%; float: left;background-color: #fff;">-->
<!--          <div style="padding: 0px 2.5%">-->
<!--            <el-row :gutter="10">-->
<!--              <el-tabs-news/>-->
<!--            </el-row>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-col>-->
<!--    </el-row>-->
  </div>


</template>

<script>
// import elTabsNews from './components/tabsNews'
// import chargingProjects from './components/chargingProjects'
import service from './service'
import latestService from './latestService'
import hotService from './hotService'
import bestSelling from './bestSelling'
export default {
  name: "Login",
  components:{service,latestService,hotService,bestSelling},
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>


<style lang="scss" scoped>
.projectName{
  font-size: 18px;
  text-align: center;
  padding-bottom: 10px;
}
.newsTitle span{
  font-size: 12px;
  color: #999;
  float: right;
}
.newsDigest{
  font-size: 20px;
  color: #f90;
  line-height: 20px;
  border-top: 1px solid #dedede;
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
}
</style>
